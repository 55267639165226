<template>
  <div>
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :disabled="true"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :disabled="true"
            label="평가명"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="true"
            :range="true"
            label="평가기간"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-project-location
            :editable="editable"
            :disabled="true"
            :plantCd="assessPlan.plantCd"
            label="작업장소"
            name="sopMapId"
            v-model="assessPlan.sopMapId"
          >
            <template slot="search">
              <q-icon v-if="assessPlan.sopMapId&&editable" name="visibility" class="cursor-pointer" @click="srcAllClick">
                <q-tooltip>
                  {{'지도 및 전체 위치 보기'}}
                </q-tooltip>
              </q-icon>
            </template>
          </c-project-location>
        </div>
        
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            :disabled="true"
            :isVendorOrg="true"
            type="user" 
            label="승인자" 
            name="approvalUserId" 
            v-model="assessPlan.approvalUserId" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            :disabled="true"
            :isVendorOrg="true"
            type="user" 
            label="검토자" 
            name="reviewUserId" 
            v-model="assessPlan.reviewUserId" />
        </div>
        <div class="col-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            label="상세내용"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="업체/작성자/작성일"
            name="request"
            v-model="request">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="평가대상 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="assessPlan.targetList"
          :merge="grid.merge"
          :columnSetting="false"
          :expandAll="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :usePaging="false"
          :hideBottom="true"
          :filtering="false"
          :editable="false"
          selection="multiple"
          rowKey="riskAssessmentTargetId"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="srcClick(props.row, props.pageIndex)" />
            </template>
            <template v-else-if="col.name==='sopName'">
              <c-text-column
                :editable="editable&&!disabled"
                :disabled="true"
                :col="col"
                :props="props"
              />
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="관련자료" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload 
                :attachInfo="attachInfo"
                label="첨부파일"
                maxheight="'max-height:500px;min-height:500px;width: 100%;'"
                :editable="false">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-plan-tl',
  props: {
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      assessPlan: {
        riskAssessmentPlanId: '',
        companyCd: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: 'RM00000001',
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        chgUserId: '',
        remark: '',
        regUserId: '',
        regDt: '',
        reviewUserId: '',
        chgDt: '',
        targetList: [], // 대상공정 목록
        deleteTargetList: [], // 대상공정 목록
        vendorList: [],
        deleteVendorList: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_ASSESS_PLAN',
        taskKey: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'processCd' },
          // { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:230px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'assessVendorId',
            field: 'assessVendorId',
            label: '평가실행(자체/업체)',
            align: 'center',
            style: 'width:400px',
            sortable: false,
            type: 'vendor',
          },
          {
            name: 'constructVendorCd',
            field: 'constructVendorCd',
            label: '공사업체',
            align: 'center',
            sortable: false,
            type: 'vendorMulti',
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '유해위험기계기구',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'workerCount',
            field: 'workerCount',
            label: '공사인원',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'src',
            field: 'src',
            label: '작업위치',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
        ],
        data: [
          
        ],
        height: '350px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.saas.assessPlan.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.riskAssessmentPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.param.riskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.assessPlan = _result.data

          this.assessPlan.deleteVendorList = this.$_.cloneDeep(this.assessPlan.vendorList)
          
          this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
            [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []
          this.$set(this.attachInfo, 'taskKey', this.assessPlan.riskAssessmentPlanId)

          if (this.$store.getters.user.innerFlag === 'Y') {
            this.editable = true;
          } else {
            if (this.$store.getters.user.deptCd === _result.data.regVendorCd) {
              this.editable = true;
            } else {
              this.editable = false;
            }
          }
        },);
      } else {
        this.assessPlan.regUserId = this.$store.getters.user.userId
        this.assessPlan.regUserName = this.$store.getters.user.userName
        this.assessPlan.regVendorName = this.$store.getters.user.deptName

        this.assessPlan.regDt = this.$comm.getToday();
      }
    },
    srcAllClick() {
      let maps = [];
      if (this.assessPlan.targetList && this.assessPlan.targetList.length > 0) {
        this.$_.forEach(this.assessPlan.targetList, _item => {
          this.$_.forEach(_item.maps, _map => {
            maps.push(_map)
          }) 
        })
      }
      this.popupOptions.title = '지도 및 전체위치 보기'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: '',
        maps: maps,
        isAll: true,
        deleteMaps: [],
        sopMapId: this.assessPlan.sopMapId,
        stepCd: '',
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    srcClick(row) {
      this.row = row;

      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        isAll: false,
        deleteMaps: row.deleteMaps ? row.deleteMaps : [],
        sopMapId: this.assessPlan.sopMapId,
        stepCd: this.assessPlan.stepCd,
        disabled: Boolean(this.assessPlan.stepCd) && this.assessPlan.stepCd !== 'RAS000001',
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
