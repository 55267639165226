var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.assessPlan.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "plantCd", $$v)
                    },
                    expression: "assessPlan.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    label: "평가명",
                    name: "assessmentName",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentName,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentName", $$v)
                    },
                    expression: "assessPlan.assessmentName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    range: true,
                    label: "평가기간",
                    name: "period",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentPeriod", $$v)
                    },
                    expression: "assessPlan.assessmentPeriod",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c(
                  "c-project-location",
                  {
                    attrs: {
                      editable: _vm.editable,
                      disabled: true,
                      plantCd: _vm.assessPlan.plantCd,
                      label: "작업장소",
                      name: "sopMapId",
                    },
                    model: {
                      value: _vm.assessPlan.sopMapId,
                      callback: function ($$v) {
                        _vm.$set(_vm.assessPlan, "sopMapId", $$v)
                      },
                      expression: "assessPlan.sopMapId",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "search" },
                      [
                        _vm.assessPlan.sopMapId && _vm.editable
                          ? _c(
                              "q-icon",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { name: "visibility" },
                                on: { click: _vm.srcAllClick },
                              },
                              [
                                _c("q-tooltip", [
                                  _vm._v(
                                    " " + _vm._s("지도 및 전체 위치 보기") + " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    isVendorOrg: true,
                    type: "user",
                    label: "승인자",
                    name: "approvalUserId",
                  },
                  model: {
                    value: _vm.assessPlan.approvalUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "approvalUserId", $$v)
                    },
                    expression: "assessPlan.approvalUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    isVendorOrg: true,
                    type: "user",
                    label: "검토자",
                    name: "reviewUserId",
                  },
                  model: {
                    value: _vm.assessPlan.reviewUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "reviewUserId", $$v)
                    },
                    expression: "assessPlan.reviewUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "상세내용",
                    name: "remark",
                  },
                  model: {
                    value: _vm.assessPlan.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "remark", $$v)
                    },
                    expression: "assessPlan.remark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "업체/작성자/작성일",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "평가대상 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.assessPlan.targetList,
                merge: _vm.grid.merge,
                columnSetting: false,
                expandAll: false,
                isFullScreen: false,
                isExcelDown: false,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                editable: false,
                selection: "multiple",
                rowKey: "riskAssessmentTargetId",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "src"
                        ? [
                            _c("q-btn", {
                              attrs: {
                                round: "",
                                unelevated: "",
                                size: "6px",
                                color: "amber",
                                icon: "search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.srcClick(
                                    props.row,
                                    props.pageIndex
                                  )
                                },
                              },
                            }),
                          ]
                        : col.name === "sopName"
                        ? [
                            _c("c-text-column", {
                              attrs: {
                                editable: _vm.editable && !_vm.disabled,
                                disabled: true,
                                col: col,
                                props: props,
                              },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "관련자료" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-upload", {
                        attrs: {
                          attachInfo: _vm.attachInfo,
                          label: "첨부파일",
                          maxheight:
                            "'max-height:500px;min-height:500px;width: 100%;'",
                          editable: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }